import { render, staticRenderFns } from "./trueMoney.vue?vue&type=template&id=251e317b&scoped=true&"
import script from "./trueMoney.vue?vue&type=script&lang=js&"
export * from "./trueMoney.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "251e317b",
  null
  
)

export default component.exports